import * as R from 'ramda'
import React from 'react'
import Img from 'gatsby-image'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

export default props => {
  return (
    <div className="container">
      <section className="filters">
        <FormControl variant="outlined" className="filter-dropdown">
          <InputLabel id="sort-label">Sort</InputLabel>
          <Select
            label="Sort"
            labelId="sort-label"
            id="sort-select"
            value={props.defaultSort}
            onChange={e => props.handleCategorySort(e.target.value)}
          >
            <MenuItem value="asc">A - Z</MenuItem>
            <MenuItem value="desc">Z - A</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="filter-dropdown">
          <InputLabel id="cats-label">Challenge</InputLabel>
          <Select
            label="Challenge"
            labelId="cats-label"
            id="cats-select"
            value={props.selectedChallengeType}
            onChange={e => props.handleChallengeTypeChange(e.target.value)}
            disabled={R.not(Array.isArray(props.challengeType))}
          >
            {Array.isArray(props.challengeType) && (
              <MenuItem value=""> - ALL - </MenuItem>
            )}
            {Array.isArray(props.challengeType) ? (
              mapIndexed(({ node: cat }, index) => {
                return (
                  <MenuItem key={index} value={cat.title}>
                    {R.toUpper(cat.title)}
                  </MenuItem>
                )
              })(
                R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
                  props.challengeType
                )
              )
            ) : (
              <MenuItem value={props.challengeType.title}>
                {R.toUpper(props.challengeType.title)}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="filter-dropdown">
          <InputLabel id="cats-label">Category</InputLabel>
          <Select
            label="Category"
            labelId="cats-label"
            id="cats-select"
            value={props.selectedCategory}
            onChange={e => props.handleCategoryChange(e.target.value)}
          >
            <MenuItem value=""> - ALL EXHIBITORS IN THIS CHALLENGE - </MenuItem>
            {Array.isArray(props.challengeType)
              ? mapIndexed(({ node: challenge }, index) => {
                  return (
                    R.equals(props.selectedChallengeType, challenge.title) &&
                    mapIndexed((cat, i) => {
                      return (
                        <MenuItem key={i} value={cat.title}>
                          {R.toUpper(cat.title)}
                        </MenuItem>
                      )
                    })(challenge.categories)
                  )
                })(
                  R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
                    props.challengeType
                  )
                )
              : notNilOrEmpty(props.challengeType.categories) &&
                mapIndexed((category, index) => {
                  return (
                    <MenuItem key={index} value={category.title}>
                      {R.toUpper(category.title)}
                    </MenuItem>
                  )
                })(
                  R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
                    props.challengeType.categories
                  )
                )}
          </Select>
        </FormControl>
        <Autocomplete
          autofill="true"
          options={R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
            props.defaultOrder
          )}
          getOptionLabel={({ node: option }) =>
            option.title || option.company_primary_contact
          }
          className="search-box filter-dropdown"
          onChange={props.onAutocompleteChange}
          classes={{
            popper: 'popper-autocomplete'
          }}
          renderInput={params => (
            <TextField
              {...params}
              className="search-box-label"
              label="Company"
              variant="outlined"
              color="secondary"
            />
          )}
        />
      </section>
      <section className="exhibitors">
        {notNilOrEmpty(props.defaultOrder) &&
          mapIndexed(({ node: company }, index) => {
            return (
              <div key={index}>
                <AniLink
                  cover
                  duration={1}
                  className="image"
                  to={`/exhibitor/${company.slug.current}`}
                  key={index}
                  bg="#000"
                >
                  <div key={index} className={`exhibitors__single animated`}>
                    <div className="company_image">
                      {notNilOrEmpty(company.company_img) && (
                        <Img
                          fluid={company.company_img.asset.fluid}
                          objectFit="scale-down"
                          objectPosition="50% 50%"
                        />
                      )}
                    </div>
                    <p className="company_title text--center">
                      {company.title}
                    </p>
                  </div>
                </AniLink>
              </div>
            )
          })(props.defaultOrder)}
      </section>
    </div>
  )
}
